/* styles.css */

@font-face {
    font-family: 'TitleFont';
    src: url('./fonts/TitleFont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'BodyFont';
    src: url('./fonts/BodyFont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  :root {
    --primary-color: #fdfd06;
    --background-color: #000000;
    --text-color: #f5f5f5;
    --title-font: 'TitleFont', Arial, sans-serif;
    --body-font: 'BodyFont', Georgia, serif;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: var(--body-font);
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--title-font);
    color: var(--primary-color);
    text-align: center;
  }
  
  p, span, div {
    font-family: var(--body-font);
    text-align: center;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
    z-index: 1000;
    font-family: var(--title-font);
  }
  
  .navbar-logo img {
    height: 40px;
  }
  
  .navbar-menu {
    display: flex;
    list-style-type: none;
  }
  
  .navbar-menu li {
    margin-left: 1rem;
  }
  
  .navbar-menu a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.7rem;
  }
  
  .hamburger {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .hero-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-gif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .hero-content {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
  }
  
  .hero-text {
    color: var(--text-color);
    margin-bottom: 1rem;
    font-size: 1.2rem;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    margin: 0.5rem;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    font-family: var(--title-font);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--background-color);
  }
  
  .btn-secondary {
    background-color: transparent;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  
  .how-it-works, .tokenomics {
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .how-it-works p {
    margin: 0 auto;
    max-width: 100%;
    margin-top: 3rem;
  }
  
  .tokenomics-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .tokenomics-item {
    flex: 1;
    min-width: 200px;
    margin: 1rem;
    text-align: center;
  }
  
  @media (orientation: landscape) {
    .how-it-works p {
      max-width: 60%;
    }
  }
  
  @media (max-width: 768px) {
    .navbar-menu {
      display: none;
    }
  
    .navbar-menu.mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 1rem;
    }
  
    .navbar-menu.mobile li {
      margin: 0.5rem 0;
    }
  
    .navbar-menu.mobile a {
      font-size: 0.7rem;
    }
  
    .hamburger {
      display: block;
    }
  
    .tokenomics-grid {
      flex-direction: column;
    }
  
    .hero-text {
      width: 80%;
    }
  }